
import BCXMarkdown from '@/components/molecules/BCXMarkdown.vue';
import BCXValidatedInput from '@/components/molecules/BCXValidatedInput.vue';
import {
  computed, defineComponent, onMounted, reactive, ref
} from 'vue';
import { useStore } from 'vue2-helpers/vuex';
import LRPopup from '@/components/login-register/LRPopup.vue';
import useVuelidate from '@vuelidate/core';
import useBCValidators from '@/mixins/useBCValidators';
import BCXCheckbox from '@/components/molecules/forms/BCXCheckbox.vue';
import BCXVuelidateErrors from '@/components/molecules/BCXVuelidateErrors.vue';
import ButtonBlock from '@/components/login-register/ButtonBlock.vue';
import useI18n from '@/mixins/useI18n';
import ByteCookieLogoFull from '@/components/svg/logo/logo-full-for-darkBG.svg?inline';
import { useRoute } from 'vue2-helpers/vue-router';
import InviteCodePopup from '@/components/login-register/InviteCodePopup.vue';
import CompanyInviteCodePopup from '@/components/login-register/CompanyInviteCodePopup.vue';
import useResponsiveness from '@/mixins/useResponsiveness';
import TranslatedBackendErrors from '@/utils/TranslatedBackendErrors';
import BackendErrors from '@/components/login-register/BackendErrors.vue';
import { TranslatedErrorCode } from '@/models/BackendError';
import { Lang, User, UserType } from '@/models/User';
// eslint-disable-next-line import/named
import { LegalItem } from '@/models/Legal';
import RegistrationService, { RegisterBasicDataRequest } from '@/services/RegistrationService';
import { useLocalStorage, useSessionStorage } from '@vueuse/core';

export default defineComponent({
  name: 'Registration',

  components: {
    BackendErrors,
    InviteCodePopup,
    ButtonBlock,
    BCXVuelidateErrors,
    BCXCheckbox,
    LRPopup,
    BCXValidatedInput,
    BCXMarkdown,
    ByteCookieLogoFull,
    CompanyInviteCodePopup
  },

  setup() {
    const { tUserTypeFallBack, locale } = useI18n();
    const store = useStore();
    const {
      emailValidation,
      required,
      termsValidation
    } = useBCValidators();
    const {
      isMobileWidth
    } = useResponsiveness();
    const route = useRoute();
    const storedUser = useLocalStorage('user', null);
    const userFormFields = useSessionStorage<any>('userFormFields', null);
    const invitingCompanyName = ref('');

    const accountType = computed(() => {
      switch (route.name) {
        case 'register-guest': return UserType.GUEST;
        case 'register-customer': return UserType.COMPANY;
        default: return UserType.FREELANCER;
      }
    });
    const isCompanyType = computed(() => accountType.value === UserType.COMPANY);
    const isGuestType = computed(() => accountType.value === UserType.GUEST);
    const isFreelancerType = computed(() => accountType.value === UserType.FREELANCER);

    const t = (key: string, ...args: any) => tUserTypeFallBack(key, accountType.value, ...args);

    const titleParts = computed(() => (t('registration_USERTYPE.title') as string).split(/ByteCookie/));

    const fields = reactive({
      firstname: '',
      lastname: '',
      companyName: '',
      email: '',
      code: '',
      checked: false,
    });

    const rules = computed(() => {
      if (isCompanyType.value) {
        return {
          firstname: { required },
          lastname: { required },
          email: emailValidation,
          companyName: { required },
          checked: termsValidation,
          code: { },
        };
      } if (isFreelancerType.value) {
        return {
          firstname: { required },
          lastname: { required },
          email: emailValidation,
          checked: termsValidation,
          code: { required },
        };
      } if (isGuestType.value) {
        return {
          firstname: { required },
          lastname: { required },
        };
      }
      return null;
    });

    const v = useVuelidate(rules, fields);

    const checkedTermsAndPrivacy = ref(false);
    const submitting = ref(false);
    const legalText = ref<LegalItem | false>(false);
    const isInvitePopupVisible = ref(false);
    const isCompanyInvitePopupVisible = ref(false);
    const backendErrors = ref<TranslatedErrorCode[] | string[]>([]);
    const userId = ref('');

    const prefillForm = async () => {
      const isGuest = isGuestType.value;
      const previousFormFields = JSON.parse(userFormFields.value ?? '{}');
      const { inviteCode } = store.getters;

      if (isGuest) {
        const guest = await RegistrationService.getRegisteringCompanyGuest(route.params?.inviteId);
        const {
          firstName, lastName, companyName, inviteId
        } = guest;
        fields.firstname = firstName ?? '';
        fields.lastname = lastName ?? '';
        fields.code = inviteId;
        invitingCompanyName.value = companyName;
      } else {
        const user = (store.getters?.user as User) ?? storedUser.value;
        if (user) {
          fields.companyName = user.company?.name ?? previousFormFields?.companyName ?? '';
          fields.firstname = user.firstname ?? '';
          fields.lastname = user.lastname ?? '';
          fields.email = user.email ?? '';
          userId.value = user.userId ?? '';
        } else {
          fields.companyName = (route?.query?.companyName as string) ?? '';
          fields.email = (route?.query?.email as string) ?? '';
          fields.firstname = (route?.query?.firstname as string) ?? '';
          fields.lastname = (route?.query?.lastname as string) ?? '';
        }
        fields.code = route?.query?.code || inviteCode || '';
      }
    };

    onMounted(() => {
      const {
        inviteCode
      } = store.getters;

      prefillForm();

      if (inviteCode) {
        checkedTermsAndPrivacy.value = true;
      } else if (route?.params?.showInvitePopup) {
        isInvitePopupVisible.value = true;
      }
      ['forum-overview-visited', 'locale', 'selected_audio_input', 'selected_camera',
        'threadsViewed', 'video_expansion_panels', 'user_roles', 'user_permissions',
        'token_expires', 'availability', 'last_reload'].forEach((item) => localStorage.removeItem(item));
    });

    const submit = async () => {
      const isValid = await v.value.$validate();
      if (!submitting.value && isValid) {
        const {
          firstname,
          lastname,
          companyName,
          email
        } = fields;

        let localeSetting = Lang.de;
        if (locale.value === 'en') {
          localeSetting = Lang.en;
        }

        let inviteCode: string | undefined = fields.code?.replace(/\s/g, '');
        if (!inviteCode.length) {
          inviteCode = undefined;
        }

        const params:RegisterBasicDataRequest = {
          userId: '',
          firstname,
          lastname,
          locale: localeSetting,

          ...isGuestType.value ? {
            companyInviteId: route.params?.inviteId,
            role: 'COMPANY_GUEST'
          } : {
            email,
            inviteCode,
            checkedAgbAndPrivacy: fields.checked,
          },
          ...isCompanyType.value && {
            companyName,
            role: 'COMPANY_ROOT'
          },
        };

        submitting.value = true;
        userFormFields.value = JSON.stringify(fields);
        const backendErrorsObject: TranslatedBackendErrors = await store.dispatch('submitBasicData', params);
        backendErrors.value = backendErrorsObject.allErrors;
        submitting.value = false;
      }
    };

    const onShow = (which: 'terms' | 'privacy') => {
      legalText.value = which;
    };

    const showHelpPopup = () => {
      if (isCompanyType.value) {
        isCompanyInvitePopupVisible.value = true;
      } else {
        isInvitePopupVisible.value = true;
      }
    };

    const showLoginWithEmail = computed(() => backendErrors.value.includes(TranslatedErrorCode.EMAIL_NOT_UNIQUE));
    const loginLinkText = computed(() => {
      if (locale.value === 'de') {
        return (t('buttons.login') as string).toLowerCase();
      }
      return t('buttons.login');
    });

    return {
      fields,
      submitting,
      legalText,
      v,
      onShow,
      submit,
      titleParts,
      showHelpPopup,
      isInvitePopupVisible,
      isMobileWidth,
      backendErrors,
      showLoginWithEmail,
      loginLinkText,
      isCompanyType,
      isGuestType,
      isFreelancerType,
      t,
      accountType,
      isCompanyInvitePopupVisible,
      invitingCompanyName
    };
  }
});
